export const DURATION = 86400 * 7

export const getCurrRound = () => {
  return Math.floor(Math.round(new Date().getTime() / 1000) / DURATION) * DURATION
}

export const getWeeklyId = () => {
  return Math.floor(Math.round(new Date().getTime() / 1000) / DURATION)
}

export const getEndRound = () => {
  return Math.floor(Math.round(new Date().getTime() / 1000) / DURATION) * DURATION + DURATION
}

const startEpoch = 1707350400

export const getEpochNum = (currId: number) => {
  return (currId * DURATION - startEpoch) / DURATION + 1
}

const getPrivateEpochNum = () => {
  return (getCurrRound() - startEpoch) / DURATION + 1
}

export const getAllEpochInfo = () => {
  const currRound = getCurrRound()
  if (currRound == startEpoch) {
    return [
      {
        epochNum: 1,
        startTime: startEpoch,
        endTime: startEpoch + DURATION,
      },
    ]
  } else {
    const epochNum = getPrivateEpochNum()
    const resArr = []
    for (let i = 1; i <= epochNum; i++) {
      resArr.push({
        epochNum: i,
        startTime: startEpoch + DURATION * (i - 1),
        endTime: startEpoch + DURATION * i,
      })
    }
    return resArr
  }
}
