import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { StyledTableRow } from 'components/Table'
import { MAX_WIDTH_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { useCurrency } from 'hooks/Tokens'
import { useRoxUtils, useTradeReader, useTradeRouter } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { TextLeftCell } from 'pages/Lock/LockRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { Box } from 'rebass'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { ExecuteBtn, StyledBonus } from '../LimitList/LimitRow'
import { AdlSortMethod } from './state'

const StyledAdlRow = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  align-items: center;
  grid-template-columns: 200px 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  padding: 0;
  margin: 0;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
  .bg {
    height: 100%;
    ${({ theme }) =>
      css`
        background-color: ${theme.hoverDefault};
      `}
  }
`

const StyledTrRow = styled(StyledAdlRow)``

const StyledHeaderRow = styled(StyledAdlRow)`
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

/* Token Row: skeleton row component */
function AdlRow({
  header,
  positions,
  pnL,
  entryPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  header: boolean
  $loading?: boolean
  size: ReactNode
  pnL?: ReactNode
  entryPrice: ReactNode
  markPrice: ReactNode
  positions: ReactNode
  bonus: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowHeaderCells = (
    <>
      <TextLeftCell data-testid="positions-cell">{positions}</TextLeftCell>
      <TextLeftCell data-testid="pnL-cell">{pnL}</TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">{entryPrice}</TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">{markPrice}</TextLeftCell>
      <TextLeftCell data-testid="size-cell">{size}</TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">{bonus}</TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">{action}</TextLeftCell>
    </>
  )
  const rowTrCells = (
    <>
      <TextLeftCell data-testid="positions-cell">
        <Box p="16px 0" width="100%" height="100%">
          {positions}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="pnL-cell">
        <Box p="16px 0" width="100%" height="100%">
          {pnL}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {entryPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="mark-price-cell">
        <Box p="16px 0" width="100%" height="100%">
          {markPrice}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="size-cell">
        <Box p="16px 0" width="100%" height="100%">
          {size}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="bonus-cell">
        <Box p="16px 0" width="100%" height="100%">
          {bonus}
        </Box>
      </TextLeftCell>
      <TextLeftCell data-testid="total-rewards-cell">
        <Box p="16px 0" width="100%" height="100%">
          {action}
        </Box>
      </TextLeftCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="adl-header-row">{rowHeaderCells}</StyledHeaderRow>
  return <StyledTrRow {...rest}>{rowTrCells}</StyledTrRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <AdlRow
      header={true}
      positions={<Trans>{AdlSortMethod.Positions}</Trans>}
      pnL={<Trans>{AdlSortMethod.PnL}</Trans>}
      entryPrice={<Trans>{AdlSortMethod.EntryPrice}</Trans>}
      markPrice={<Trans>{AdlSortMethod.MarkPrice}</Trans>}
      size={<Trans>{AdlSortMethod.Size}</Trans>}
      bonus={<Trans>{AdlSortMethod.Bonus}</Trans>}
      action={<Trans>{AdlSortMethod.Action}</Trans>}
    />
  )
}
/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <AdlRow
      header={false}
      $loading
      positions={
        <Row gap="xs">
          <IconLoadingBubble width="40px" height="40px" />
          <MediumLoadingBubble />
        </Row>
      }
      pnL={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
          <MediumLoadingBubble />
        </Column>
      }
      entryPrice={
        <Row justify="flex-start" gap="xs">
          <LoadingBubble />
        </Row>
      }
      markPrice={
        <Row justify="flex-start" gap="xs">
          <LoadingBubble />
        </Row>
      }
      size={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      action={
        <Column align="flex-start" width="100%" gap="xs">
          <MediumLoadingBubble />
        </Column>
      }
      {...props}
    />
  )
}

export interface LoadedRowProps {
  token01: boolean
  adlListIndex: number
  adlListLength: number
  adl: any
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { adlListIndex, adlListLength, adl, token01 } = props
  const theme = useTheme()
  const currency0 = useCurrency(adl.v3Pool.token0.id)
  const currency1 = useCurrency(adl.v3Pool.token1.id)
  const twapPrice = adl.twapPrice

  const decimals = adl.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = adl.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = adl.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = adl.long0 ? currency1?.symbol : currency0?.symbol

  const lever = useMemo(() => {
    const collateral = adl.collateral
    return BN(adl.size).div(collateral)
  }, [adl.collateral, adl.size])
  //execTakingProfitSet
  const routerContract = useTradeRouter()
  const readerContract = useTradeReader()
  const roxUtilsContract = useRoxUtils()
  const { result: poolReserveRate } = useSingleCallResult(readerContract, 'poolReserveRate', [adl.spotPoolAddress])
  const { result: poolSetting } = useSingleCallResult(roxUtilsContract, 'poolSetting', [adl.spotPoolAddress])
  const canExec = useMemo(() => {
    if (!poolReserveRate || !poolSetting) return false
    if (adl.long0) {
      return Number(poolReserveRate[0]) / 1000000 >= poolSetting.setlThres /1000
    } else {
      return Number(poolReserveRate[1]) / 1000000 >= poolSetting.setlThres /1000
    }
  }, [adl.long0, poolReserveRate,poolSetting])
  const handleEecu = async () => {
    try {
      await routerContract?.execTakingProfitSet(adl.prepPoolAddress, adl.key)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div ref={ref} data-testid={`adl-table-row-${adl.lpAddr}`}>
        <AdlRow
          header={false}
          positions={
            <Row>
              <DoubleCurrencyLogo
                size={40}
                margin
                currency0={token01 ? currency1 ?? undefined : currency0 ?? undefined}
                currency1={token01 ? currency0 ?? undefined : currency1 ?? undefined}
              />
              <Column gap="xs" align="flex-start" ml="16px">
                <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                  {token01 ? `${currency0?.symbol}/${currency1?.symbol}` : `${currency1?.symbol}/${currency0?.symbol}`}
                </ThemedText.TextPrimary>
                <Row gap="xs">
                  <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                    {lever.toFixed(2)}x
                  </ThemedText.TextSecondary>
                  {token01 ? adl.long0 ? <LongIcon /> : <ShortIcon /> : !adl.long0 ? <LongIcon /> : <ShortIcon />}
                </Row>
              </Column>
            </Row>
          }
          pnL={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.BodyPrimary fontWeight={700} fontSize={16} color={adl?.hasProfit ? 'up' : 'down'}>
                {countZeros(fromWei(adl.profitDeltaX, decimals).toString())} {sizeymbol}
              </ThemedText.BodyPrimary>
              <ThemedText.BodyPrimary fontSize={14} color={adl?.hasProfit ? 'up' : 'down'}>
                {adl?.hasProfit ? '+' : ''}
                {countZeros(adl?.pnl)}%
              </ThemedText.BodyPrimary>
            </Column>
          }
          entryPrice={
            <Row justify="flex-start" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {!token01 ? countZeros(adl.entry0Price) : countZeros(adl.entry1Price)}
              </ThemedText.TextPrimary>
            </Row>
          }
          markPrice={
            <Row justify="flex-start" gap="xs">
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(token01 ? twapPrice : 1 / twapPrice)}
              </ThemedText.TextPrimary>
            </Row>
          }
          size={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary>
                {countZeros(fromWei(adl.size, decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(adl.adlResv, feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Column align="flex-start" gap="xs">
              <ExecuteBtn onClick={handleEecu} disabled={!canExec}>
                <Trans>{canExec ? 'Execute' : 'Not available'}</Trans>
              </ExecuteBtn>
            </Column>
          }
          first={adlListIndex === 0}
          last={adlListIndex === adlListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
