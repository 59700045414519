import { Trans } from '@lingui/macro'
import { Box as Box2, Checkbox } from '@mui/material'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ReactComponent as AddIcon } from 'assets/imgs/add2.svg'
import { ReactComponent as ToUpperIcon } from 'assets/imgs/to_upper.svg'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { StyledToIcon } from 'components/Icons/StyledIcon'
import Row, { RowBetween } from 'components/Row'
import { ConfirmationModalContent, TransactionConfirmationModal22222 } from 'components/TransactionConfirmationModal'
import { useRoguxCurrency } from 'constants/tokens'
import dayjs from 'dayjs'
import { useLockContract } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useWithdrawInfo } from 'pages/Lock/hooks'
import { useCallback, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, toFromBN } from 'utils/bn'
import { DURATION } from 'utils/CurrRound'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { AmountBox, BoxCard, StyledLockCard } from '../CreateLockModal/CreateLockModal'
import LockPicker from '../CreateLockModal/LockPicker'
import TimeCard from '../CreateLockModal/TimeCard'

export const ModalTabCard = styled(Row)`
  margin-top: 8px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.primaryBorder};

  .tabItem {
    flex: 1;
    height: 100%;
    border-radius: 18px;
    cursor: pointer;
  }
  .tabItemActive {
    background-color: ${({ theme }) => theme.connectBtnBackground};
  }
`

const EndCard = styled(Row)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
  border-radius: 16px;
`

export const StyledCheckbox = styled(Checkbox)``

interface ManageModalProps {
  isOpen: boolean
  onDismiss: () => void
  tokenId: string
  endTime: string
  veAmount: any
  lockAmount: CurrencyAmount<Currency>
}
export default function ManageModal({ isOpen, onDismiss, tokenId, endTime, veAmount, lockAmount }: ManageModalProps) {
  const theme = useTheme()
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Add', 'Split', 'Unlock']
  const isDisabled = useMemo(() => curr == 2, [curr])

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismiss = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }
  const [defaultCheckedValue, setCheckValue] = useState(false)
  const [weeks, setWeeks] = useState(1)
  const leftTime = () => {
    const currTime = Math.round(Number(new Date()) / 1000)
    const roundTime = Math.floor(currTime / DURATION) * DURATION + DURATION
    return 7 - Math.round((roundTime - currTime) / 86400)
  }
  const unLockDate = useMemo(() => {
    return dayjs()
      .add(weeks * 7 - leftTime(), 'day')
      .format('YYYY-MM-DD')
  }, [weeks])

  const isIncreaseTime = useMemo(() => {
    return (
      dayjs()
        .add(weeks * 7 - leftTime(), 'day')
        .unix() > Number(endTime)
    )
  }, [endTime, weeks])

  const RoguxCurrency = useRoguxCurrency()
  const { inputAmount: amount, handleUserInput: setAmount } = useInputAmount(RoguxCurrency)
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )

  const withdrawInfo = useWithdrawInfo(tokenId)

  const LockContract = useLockContract()

  const addTransaction = useTransactionAdder()

  const { result: withdrawForceResult } = useSingleCallResult(LockContract, 'withdrawForceCalculate', [tokenId])
  const receiveAmount = useMemo(() => {
    if (!withdrawForceResult) return
    return toFromBN(withdrawForceResult[0])
  }, [withdrawForceResult])

  const handleAddLock = useCallback(async () => {
    if (!LockContract || !tokenId) return

    if (weeks) {
      setAttemptingTxn(true)
      try {
        const response = await LockContract.increase_unlock_time(tokenId, DURATION * weeks)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.ADD_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        console.log('add Lock time error', error)
      }
    }

    if (amount.amount?.numerator.toString()) {
      setAttemptingTxn(true)
      try {
        const response = await LockContract.increase_amount(tokenId, amount.amount.numerator.toString())
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.ADD_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } catch (error) {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        console.log('add Lock error', error)
      }
    }
  }, [LockContract, addTransaction, amount, tokenId, weeks])

  const handleUnLock = useCallback(async () => {
    if (!LockContract) return
    setAttemptingTxn(true)

    const nowTime = Math.floor(Date.now() / 1000)

    try {
      if (nowTime - Number(endTime) < 0) {
        const response = await LockContract.withdrawForce(tokenId)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.REMOVE_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      } else {
        const response = await LockContract.withdraw(tokenId)
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.REMOVE_LOCK,
          token0Address: '',
          token1Address: '',
        })
        setTxHash(response.hash)
      }
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('unLock error', error)
    }
  }, [LockContract, addTransaction, endTime, tokenId])

  const handleSplit = useCallback(async () => {
    if (!LockContract || !tokenId || !amount.amount) return
    setAttemptingTxn(true)
    try {
      const response = await LockContract.split(tokenId, amount.amount.numerator.toString())
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.SPLIT_LOCK,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('split error', error)
    }
  }, [LockContract, addTransaction, amount.amount, tokenId])

  const handleMaxChange = (value: any) => {
    if (curr == 0) {
      handleAmountChange(value)
    } else if (curr == 1) {
      handleAmountChange(
        lockAmount
          .multiply(value * 1000)
          .divide(1000)
          .toSignificant(4)
      )
    }
  }

  const hasStakedTime = useMemo(() => {
    if (Math.round(Number(new Date()) / 1000) > Number(endTime)) return 0
    return (
      ((Math.round(Number(new Date()) / 1000) - Number(withdrawInfo?.create_lock_time)) /
        (Number(endTime) - Number(withdrawInfo?.create_lock_time))) *
      100
    )
  }, [endTime, withdrawInfo?.create_lock_time])

  function ModalContent() {
    return (
      <>
        <ModalTabCard>
          {tabList?.map((tab: any, i: number) => (
            <Row
              justify="center"
              className={`tabItem ${curr == i && 'tabItemActive'}`}
              key={i}
              onClick={() => {
                setCurr(i)
                setAmount('')
              }}
            >
              {curr == i ? (
                <ThemedText.BodySmall fontWeight={700} color="textAddButton" className="">
                  {tab}
                </ThemedText.BodySmall>
              ) : (
                <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                  {tab}
                </ThemedText.TextSecondary>
              )}
            </Row>
          ))}
        </ModalTabCard>
        <AmountBox>
          <ThemedText.HeadNav054>
            <Trans>Amounts</Trans>
          </ThemedText.HeadNav054>
          <BoxCard isDisabled={isDisabled}>
            <Box p=" 0 8px" pb="12px">
              <SwapCurrencyInputPanel
                label={<Trans>From</Trans>}
                value={curr == 2 ? lockAmount.toSignificant(4) : amount.value}
                showMaxButton={false}
                currency={curr != 0 && lockAmount ? RoguxCurrency : lockAmount?.currency}
                id="LockAmount"
                onUserInput={handleAmountChange}
                // onMax={handleMaxInput}
                fiatValue={{ data: Number(amount), isLoading: false }}
                // onCurrencySelect={handleInputSelect}
                // otherCurrency={currencies[Field.OUTPUT]}
                loading={false}
                onMaxTab={handleMaxChange}
                showMaxTab={!isDisabled}
                disabled={isDisabled}
                renderBalance={
                  curr != 0
                    ? () => {
                      return `Balance: ${lockAmount.toSignificant(4)}`
                    }
                    : undefined
                }
              />
            </Box>
          </BoxCard>
        </AmountBox>
        {curr == 0 && (
          <>
            <LockPicker title="Lock Until" />
            <Column gap="xmd" mt="8px">
              <TimeCard setWeeks={setWeeks} />
              <StyledLockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Voting Power</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      <Trans>{formatAmount(veAmount.toSignificant(), 2, true)} veROX</Trans>
                    </ThemedText.TextSecondary>
                    {(Number(amount?.value) > 0 || isIncreaseTime) && (
                      <>
                        <StyledToIcon />
                        <ThemedText.TextPrimary fontWeight={700}>
                          <Trans>
                            {!isIncreaseTime
                              ? formatAmount(
                                BN(veAmount.toSignificant()).plus(
                                  BN(amount?.value).times(
                                    BN(endTime.toString())
                                      .minus(BN(withdrawInfo?.create_lock_time.toString()))
                                      .dividedBy(BN(86400))
                                      .dividedBy(1456)
                                  )
                                ),
                                2,
                                true
                              )
                              : formatAmount(
                                ((Number(lockAmount.toSignificant()) + Number(amount?.value)) *
                                  (weeks * 7 - leftTime())) /
                                365 /
                                4,
                                2,
                                true
                              )}{' '}
                            veROX
                          </Trans>
                        </ThemedText.TextPrimary>
                      </>
                    )}
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Expire in</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>
                        {formatAmount(
                          Math.round((Number(endTime) - Math.round(Number(new Date()) / 1000)) / 86400),
                          0,
                          true
                        )}{' '}
                        Days
                      </Trans>
                    </ThemedText.TextSecondary>
                    {isIncreaseTime && withdrawInfo && (
                      <>
                        <StyledToIcon />
                        <ThemedText.TextPrimary>
                          <Trans>{formatAmount(weeks * 7 - leftTime(), 2, true)} Days</Trans>
                        </ThemedText.TextPrimary>
                      </>
                    )}
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Lock Until</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>{dayjs(Number(endTime) * 1000).format('YYYY-MM-DD')}</Trans>
                    </ThemedText.TextSecondary>
                    {isIncreaseTime && (
                      <>
                        <StyledToIcon />
                        <ThemedText.TextPrimary>
                          <Trans>{unLockDate}</Trans>
                        </ThemedText.TextPrimary>
                      </>
                    )}
                  </Row>
                </RowBetween>
              </StyledLockCard>
              <ButtonConfirmed onClick={handleAddLock}>Add</ButtonConfirmed>
            </Column>
          </>
        )}

        {curr == 1 && (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ToUpperIcon />
            </Box>

            <LockPicker title="veNFT" />
            <Column gap="xmd">
              <StyledLockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>LockAmount</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>{formatAmount(Number(amount?.value), 2, true)}</Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Voting Power</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      <Trans>
                        {' '}
                        {lockAmount &&
                          veAmount &&
                          formatAmount(
                            BN(veAmount.toSignificant()).times(amount?.value).div(lockAmount.toSignificant()),
                            2,
                            true
                          )}{' '}
                        veROX
                      </Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Lock Until</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>{dayjs(Number(endTime) * 1000).format('YYYY-MM-DD')}</Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
              </StyledLockCard>
            </Column>
            <Box display="flex" justifyContent="center" alignItems="center">
              <AddIcon />
            </Box>
            <LockPicker title="veNFT" />
            <Column gap="xmd">
              <StyledLockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>LockAmount</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>
                        {amount?.amount &&
                          lockAmount &&
                          formatAmount(lockAmount.subtract(amount.amount).toSignificant(), 2, true)}
                      </Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Voting Power</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      <Trans>
                        {' '}
                        {lockAmount &&
                          amount?.amount &&
                          veAmount &&
                          formatAmount(
                            BN(veAmount.toSignificant())
                              .times(lockAmount.subtract(amount.amount).toSignificant())
                              .div(lockAmount.toSignificant()),
                            2,
                            true
                          )}{' '}
                        veROX
                      </Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Lock Until</Trans>
                  </ThemedText.TextSecondary>
                  <Row gap="sm" width="auto">
                    <ThemedText.TextSecondary fontSize={14}>
                      <Trans>{dayjs(Number(endTime) * 1000).format('YYYY-MM-DD')}</Trans>
                    </ThemedText.TextSecondary>
                  </Row>
                </RowBetween>
              </StyledLockCard>
            </Column>

            <ButtonConfirmed onClick={handleSplit}>Split</ButtonConfirmed>
          </>
        )}

        {curr == 2 && (
          <>
            <Column gap="xmd" mt="8px">
              <EndCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans> Starts in</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    <Trans>{dayjs(Number(withdrawInfo?.create_lock_time) * 1000).format('YYYY-MM-DD')}</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </EndCard>
              <EndCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Ends in</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    <Trans>{dayjs(Number(endTime) * 1000).format('YYYY-MM-DD')}</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </EndCard>

              <ThemedText.BodySmall color="removeBackground">
                <Trans>
                  *As there is still {formatAmount(100 - hasStakedTime, 2, true)}% lock time remaining, you can only get{' '}
                  {formatAmount(hasStakedTime, 2, true)}% ROX back if you unlock it now. Rest of the{' '}
                  {formatAmount(100 - hasStakedTime, 2, true)}% ROX will be burnt by the contract.
                </Trans>
              </ThemedText.BodySmall>
              <StyledLockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Receive</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700}>
                    <Trans>{formatAmount(receiveAmount, 2, true)} ROX</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </StyledLockCard>
              <Box2
                style={{ cursor: 'pointer' }}
                bgcolor={theme.agreeBg}
                padding="8px 0"
                borderRadius="8px"
                display="flex"
                onClick={() => setCheckValue(!defaultCheckedValue)}
              >
                <StyledCheckbox checked={defaultCheckedValue} />
                {defaultCheckedValue ? (
                  <ThemedText.TextPrimary fontWeight={700} fontSize={14}>
                    I understand that once I unlock it before the expiration date, I will lose the amount of ROX
                    corresponding to the percentage of lock time remaining.
                  </ThemedText.TextPrimary>
                ) : (
                  <ThemedText.TextTertiary fontWeight={700} fontSize={14}>
                    I understand that once I unlock it before the expiration date, I will lose the amount of ROX
                    corresponding to the percentage of lock time remaining.
                  </ThemedText.TextTertiary>
                )}
              </Box2>
              <ButtonConfirmed disabled={!defaultCheckedValue} onClick={handleUnLock}>
                Unlock
              </ButtonConfirmed>
            </Column>
          </>
        )}
      </>
    )
  }

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="400px"
      reviewContent={
        <ConfirmationModalContent
          title={<Trans>Manage Lock</Trans>}
          onDismiss={handleDismiss}
          topContent={ModalContent}
        />
      }
      pendingText={pendingText}
    />
  )
}
