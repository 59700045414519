import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import { ManagerButton } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { useTradeReader, useTradeRouter } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { ExecuteBtn, StyledBonus } from '../LimitList/LimitRow'
import { LoadedRowProps } from './AdlRow'
import { AdlSortMethod } from './state'

/* Token Row: skeleton row component */
function AdlRow({
  positions,
  pnL,
  entryPrice,
  markPrice,
  size,
  bonus,
  action,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  size: ReactNode
  bonus: ReactNode
  pnL: ReactNode
  entryPrice: ReactNode
  markPrice: ReactNode
  positions: ReactNode
  action: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween gap="sm">
        <TextLeftCell data-testid="pool-cell">{positions}</TextLeftCell>
        <TextRightCell data-testid="size-cell">{size}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="pnL-cell">{pnL}</TextLeftCell>
        <TextRightCell data-testid="vote-power-cell">{entryPrice}</TextRightCell>
        <TextRightCell data-testid="vote-power-cell">{markPrice}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextRightCell data-testid="bonus-cell">{bonus}</TextRightCell>
        <TextRightCell data-testid="action-cell">{action}</TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <AdlRow
      $loading
      positions={
        <Row gap="sm">
          <IconLoadingBubble width="48px" height="48px" />
          <SmallLoadingBubble />
        </Row>
      }
      pnL={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{AdlSortMethod.PnL}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <SmallLoadingBubble />
        </Column>
      }
      entryPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{AdlSortMethod.EntryPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      markPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{AdlSortMethod.MarkPrice}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      size={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{AdlSortMethod.Size}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      bonus={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{AdlSortMethod.Bonus}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
        </Column>
      }
      action={
        <Row justify="flex-end">
          <ManagerButton>Manager</ManagerButton>
        </Row>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { adlListIndex, adlListLength, adl, token01 } = props
  const theme = useTheme()
  const currency0 = useCurrency(adl.v3Pool.token0.id)
  const currency1 = useCurrency(adl.v3Pool.token1.id)
  const twapPrice = adl.twapPrice

  const decimals = adl.long0 ? currency1?.decimals : currency0?.decimals
  const feesymbol = adl.long0 ? currency0?.symbol : currency1?.symbol
  const feesdecimals = adl.long0 ? currency0?.decimals : currency1?.decimals
  const sizeymbol = adl.long0 ? currency1?.symbol : currency0?.symbol

  const lever = useMemo(() => {
    const collateral = adl.collateral
    return BN(adl.size).div(collateral)
  }, [adl.collateral, adl.size])
  //execTakingProfitSet
  const routerContract = useTradeRouter()
  const readerContract = useTradeReader()
  const { result: poolReserveRate } = useSingleCallResult(readerContract, 'poolReserveRate', [adl.spotPoolAddress])
  const canExec = useMemo(() => {
    if (!poolReserveRate) return false
    if (adl.long0) {
      return Number(poolReserveRate[0]) / 1000000 >= 0.8
    } else {
      return Number(poolReserveRate[1]) / 1000000 >= 0.8
    }
  }, [adl.long0, poolReserveRate])
  const handleEecu = async () => {
    try {
      await routerContract?.execTakingProfitSet(adl.prepPoolAddress, adl.key)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div ref={ref} data-testid={`adl-table-row-${adl?.tokenId}`}>
        <AdlRow
          positions={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.Positions}</Trans>
              </ThemedText.TextSecondary>
              <Row>
                <DoubleCurrencyLogo
                  size={40}
                  margin
                  currency0={token01 ? currency1 ?? undefined : currency0 ?? undefined}
                  currency1={token01 ? currency0 ?? undefined : currency1 ?? undefined}
                />
                <Column gap="xs" align="flex-start" ml="8px">
                  <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                    {token01
                      ? `${currency0?.symbol}/${currency1?.symbol}`
                      : `${currency1?.symbol}/${currency0?.symbol}`}
                  </ThemedText.TextPrimary>
                  <Row gap="xs">
                    <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                      {lever.toFixed(2)}x
                    </ThemedText.TextSecondary>
                    {token01 ? adl.long0 ? <LongIcon /> : <ShortIcon /> : !adl.long0 ? <LongIcon /> : <ShortIcon />}
                  </Row>
                </Column>
              </Row>
            </Column>
          }
          pnL={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.PnL}</Trans>
              </ThemedText.TextSecondary>
              <Column align="flex-start" width="100%" gap="xs">
                <ThemedText.BodyPrimary fontWeight={700} fontSize={16} color={adl?.hasProfit ? 'up' : 'down'}>
                {countZeros(fromWei(adl.profitDeltaX, decimals).toString())} {sizeymbol}
                </ThemedText.BodyPrimary>
                <ThemedText.BodyPrimary fontSize={14} color={adl?.hasProfit ? 'up' : 'down'}>
                  {adl?.hasProfit ? '+' : ''}
                  {countZeros(adl?.pnl)}%
                </ThemedText.BodyPrimary>
              </Column>
            </Column>
          }
          entryPrice={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.EntryPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {!token01 ? countZeros(adl.entry0Price) : countZeros(adl.entry1Price)}
              </ThemedText.TextPrimary>
            </Column>
          }
          markPrice={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.MarkPrice}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                {countZeros(token01 ? twapPrice : 1 / twapPrice)}
              </ThemedText.TextPrimary>
            </Column>
          }
          size={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.Size}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary>
                {' '}
                {countZeros(fromWei(adl.size, decimals).toFixed())} {sizeymbol}
              </ThemedText.TextSecondary>
            </Column>
          }
          bonus={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{AdlSortMethod.Bonus}</Trans>
              </ThemedText.TextSecondary>
              <StyledBonus className={`${theme.darkMode ? 'active-gradient-text-dark' : 'active-gradient-text'}`}>
                {countZeros(fromWei(adl.liqResv, feesdecimals).toFixed())} {feesymbol}
              </StyledBonus>
            </Column>
          }
          action={
            <Row justify="end">
              <ExecuteBtn onClick={handleEecu} disabled={!canExec}>
                <Trans>{canExec ? 'Execute' : 'Not available'}</Trans>
              </ExecuteBtn>
            </Row>
          }
          first={adlListIndex === 0}
          last={adlListIndex === adlListLength - 1}
        />
      </div>
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
