import { BigNumber } from 'ethers'
import { useRoxUtils, useTradeReader } from 'hooks/useContract'
import { useOrderLiquidationList } from 'hooks/useQueryLiquidity'
import { useAtomValue } from 'jotai/utils'
import { useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { BN, formFeeWei, fromSqrt96Wei } from 'utils/bn'

import { filterStringAtom } from './state'

export const useALDandLiqData = () => {
  const data = useOrderLiquidationList()
  const roxutils = useRoxUtils()
  const reader = useTradeReader()
  const twapPircePrams = useMemo(() => {
    if (!data) return [[undefined]]
    return data.map((x) => [x.spotPoolAddress])
  }, [data])

  const feePrams = useMemo(() => {
    if (!data) return [[undefined]]
    return data.map((x) => [x.prepPoolAddress])
  }, [data])

  const posInfoPrams = useMemo(() => {
    if (!data) return [[undefined]]
    return data.map((x) => [x.prepPoolAddress, x.key])
  }, [data])

  const feeList = useSingleContractMultipleData(reader, 'fee', feePrams)
  const lists = useSingleContractMultipleData(roxutils, 'getSqrtTwapX96', twapPircePrams)
  const poslists = useSingleContractMultipleData(reader, 'posDispInfo', posInfoPrams)

  const loading = lists.some(({ loading }) => loading)
  const error = lists.some(({ error }) => error)

  const feeloading = feeList.some(({ loading }) => loading)
  const feeerror = feeList.some(({ error }) => error)

  const posloading = poslists.some(({ loading }) => loading)
  const poserror = poslists.some(({ error }) => error)

  const all = useMemo(() => {
    if (
      !data ||
      !lists ||
      !poslists ||
      loading ||
      error ||
      posloading ||
      poserror ||
      feeloading ||
      feeerror ||
      lists.length == 0 ||
      feeList.length == 0 ||
      poslists.length == 0
    )
      return
    return data.reduce((acc: any, cur, index) => {
      const { result: twap } = lists[index]
      const { result: posInfo } = poslists[index]
      const { result: feeInfo } = feeList[index]
      const data = {
        ...cur,
        twapPrice: BigNumber.from(0),
        pnl: '0',
        hasProfit: false,
      }
      if (!twap || !posInfo || !feeInfo) {
        acc.push(data)
        return acc
      }
      const price0 = fromSqrt96Wei(
        twap.sqrtPriceX96.toString(),
        Number(data.v3Pool.token0.decimals),
        Number(data.v3Pool.token1.decimals)
      )
      const liqPrice = fromSqrt96Wei(
        posInfo.posx.liqSqrtPriceX96.toString(),
        Number(data.v3Pool.token0.decimals),
        Number(data.v3Pool.token1.decimals)
      )
      const Openpnl = data.long0
        ? price0.minus(data.entry1Price).dividedBy(data.entry1Price).multipliedBy(data.size.toString()).toString()
        : BN(data.entry1Price).minus(price0).dividedBy(data.entry1Price).multipliedBy(data.size.toString()).toString()

      const positionFeeint = formFeeWei(feeInfo[0].positionFee)
      const positionFee = BN(data.size.toString()).times(positionFeeint)
      const borrowFee = posInfo.posx.fundingFee.toString()

      const allFee = positionFee.times(2).plus(borrowFee).toString()

      // // netvalue
      // const currentCollateral = formcollateral.minus(positionFee).toFixed()

      const liqResvValue = data.long0
        ? BN(posInfo.posx.collateral.toString())
            .div(price0)
            .div(BN(10).pow(data.v3Pool.token1.decimals))
            .multipliedBy(BN(10).pow(data.v3Pool.token0.decimals))
        : BN(posInfo.posx.collateral.toString())
            .multipliedBy(price0)
            .div(BN(10).pow(data.v3Pool.token0.decimals))
            .multipliedBy(BN(10).pow(data.v3Pool.token1.decimals))

      const adlResvValue = data.long0
        ? BN(posInfo.posx.delta.toString())
            .div(price0)
            .div(BN(10).pow(data.v3Pool.token1.decimals))
            .multipliedBy(BN(10).pow(data.v3Pool.token0.decimals))
        : BN(posInfo.posx.delta.toString())
            .multipliedBy(price0)
            .div(BN(10).pow(data.v3Pool.token0.decimals))
            .multipliedBy(BN(10).pow(data.v3Pool.token1.decimals))
      const pnLAfterFees = BN(Openpnl).minus(allFee)
      const AfterHasProfiet = pnLAfterFees.gt(BN(0))
      const pnl = pnLAfterFees.div(posInfo.posx.collateral.toString()).times(100).toString()
      data.twapPrice = price0.toNumber()
      data.profitDeltaX = pnLAfterFees
      data.pnl = pnl
      data.hasProfit = AfterHasProfiet
      data.liqResv = liqResvValue.div(20).toString()
      data.adlResv = AfterHasProfiet ? adlResvValue.div(10).toString() : '0'
      data.isLiq = posInfo.posx.isLiq
      data.close0Price =liqPrice.toString()
      acc.push(data)
      return acc
    }, [])
  }, [data, error, feeList, feeerror, feeloading, lists, loading, poserror, poslists, posloading])
  return useFilteredaldLiq(all)
}

const useFilteredaldLiq = (voteData: any[] | undefined) => {
  const filterString = useAtomValue(filterStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const { token0, token1 } = token.v3Pool
        const allStrsymbol = token0.symbol + token1.symbol
        const allStrNAME = token0.name + token1.name
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
